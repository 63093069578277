import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  meta,
  personalTimeline,
  sports,
  hobbies,
} from "helper/constants/content_option";
import Grids from "components/Section/grid";
import Table from "components/Section/table";

export const About = () => {
  return (
    <HelmetProvider>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Persona | {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <Row className="mb-5 mt-3 pt-md-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Persona</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">{dataabout.title}</h3>
          </Col>
          <Col lg="7" className="d-flex align-items-center">
            <div>
              <p>{dataabout.aboutme}</p>
            </div>
          </Col>
        </Row>
        <Row className=" sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Life Timeline</h3>
          </Col>
          <Col lg="7">
            <Table list={personalTimeline} />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lg="5">
            <h3 className="color_sec py-4">Sports</h3>
          </Col>
          <Col lg="7">
            <Grids list={sports} number={3} />
          </Col>
        </Row>
        <Row className="sec_sp">
          <Col lang="5">
            <h3 className="color_sec py-4">Hobbies</h3>
          </Col>
          <Col lg="7">
            <Grids list={hobbies} number={4} />
          </Col>
        </Row>
      </Container>
    </HelmetProvider>
  );
};
