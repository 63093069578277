import React from "react";

function Grids({ list, number }) {
    return (
        <div
            className={`grid ${
                number == 3 ? "grid-cols-3" : number == 4 ? "grid-cols-4" : ""
            }`}
        >
            {list.map((data, i) => {
                return (
                    <div className="grid-item" key={i}>
                        {React.cloneElement(data?.icon, {})}
                        <h3 className="grid-title">{data.name}</h3>
                    </div>
                );
            })}
        </div>
    );
}

export default Grids;
