import {
  FaAngular,
  FaBaseballBall,
  FaBook,
  FaCamera,
  FaCss3,
  FaDiscord,
  FaFigma,
  FaHeadphones,
  FaHtml5,
  FaJava,
  FaJira,
  FaNodeJs,
  FaPlane,
  FaPython,
  FaReact,
  FaRunning,
  FaSwimmer,
} from "react-icons/fa";
import {
  SiAxios,
  SiBootstrap,
  SiDialogflow,
  SiDotnet,
  SiExpress,
  SiFirebase,
  SiFlask,
  SiJavascript,
  SiMiro,
  SiMongodb,
  SiMui,
  SiMysql,
  SiNotion,
  SiPostman,
  SiRedux,
  SiTailwindcss,
} from "react-icons/si";
import { BsFiletypeScss, BsGithub } from "react-icons/bs";
import { BiLogoPostgresql } from "react-icons/bi";
import { DiGithubFull } from "react-icons/di";
import {
  TbBrandReactNative,
  TbHexagonLetterC,
  TbBrandNextjs,
  TbBrandVscode,
  TbSquareLetterV,
} from "react-icons/tb";
const logotext = "AK";
const meta = {
  title: "Asu Kaur",
  description: "Meet Asu Kaur!",
};

const introdata = {
  title: "I’m Asu Kaur",
  animated: {
    first: "I enjoy developing web and mobile applications.",
    second: "I love reading.",
    third: "Curious learner, avid reader, travel enthusiast.",
  },
  description:
    "Tech-savvy BTech graduate with over 3 years of hands-on experience in website and app development. Proven leader from school to the workplace, with a passion for sports, literature, and travel, and a relentless pursuit of knowledge and excellence.",
  your_img_url: "/assets/images/pic.jpg",
};

const dataabout = {
  title: "Let me introduce myself",
  aboutme:
    "I hold a BTech Degree in Computer Science and Engineering and possess over 3 years of hands-on experience in developing websites and applications, managing the entire process from inception to production. My leadership skills were honed as the deputy head girl in school, event organizer in college, and technical team lead in my workspace. An athletic enthusiast, I have a deep love for sports, particularly basketball and swimming. I am driven, self-motivated, and possess a strong determination to acquire knowledge. I find solace in the world of books, music, and enjoy exploring new destinations through travel.",
};

const dataacademic = {
  title: "A little about my education",
  aboutme:
    "I graduated high school with an outstanding 95%, actively participating in school sports. In higher secondary school, I achieved 92%, was a key member of the basketball team, and served as the Deputy Head Girl in the student council. I later earned a BTech degree in Computer Science & Engineering with an impressive overall GPA of 9.22, engaging passionately in both sports and event planning activities.",
};

const datawork = {
  title: "A little about my work",
  aboutme:
    "I embarked on my professional journey in August 2020 as a technical writer intern during my studies. Transitioning to a web developer role in early 2021, I progressed steadily through different positions, eventually reaching the role of Senior Full Stack Lead. Presently, I serve as the Senior Full Stack Lead at Caarya, overseeing the end-to-end development of websites and applications, from conception to production, while mentoring my junior associates along the way.",
};
const personalTimeline = [
  {
    name: "Graduated College",
    when: "August 2021",
  },
  {
    name: "Graduated Higher Secondary",
    when: "April 2017",
  },
  {
    name: "Graduated High School",
    when: "March 2015",
  },
  {
    name: "Born",
    when: "March, 1999",
  },
];

const education = [
  {
    name: "Bachelor of Technology in Computer Science & Engineering",
    description: (
      <>
        GPA: 9.22 <br />
        Maulana Abul Kalam Azad University of Technology, West Bengal
      </>
    ),
    when: "2021",
    marks: "9.22",
  },
  {
    name: "Indian School Certificate",
    description: (
      <>
        Score: 92% <br />
        Council for the Indian School Certificate Examinations (CISCE)
      </>
    ),
    when: "2017",
    marks: "92%",
  },
  {
    name: "Indian Certificate of Secondary Education",
    when: "2015",
    description: (
      <>
        Score: 95% <br />
        Council for the Indian School Certificate Examinations (CISCE)
      </>
    ),
    marks: "95%",
  },
];
const worktimeline = [
  {
    name: "Senior Full Stack Lead",
    where: "Caarya",
    when: "July 2022 - Present",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Accomplished completion of 12 projects and currently engaged in the
          successful execution of 16 ongoing projects.
        </li>
        <li>
          Demonstrated strong leadership abilities by guiding teams consisting
          of approximately 30 developers, 4 designers, and 6 project managers to
          effectively fulfil client requirements.
        </li>
        <li>
          Collaborated with the CEO/CTO to establish weekly and monthly goals
          for the entire Tech department, while working closely with the Project
          Manager and Product Manager to define daily tasks and weekly
          objectives for the team.
        </li>
        <li>
          Orchestrated client meetings to ensure a thorough understanding of
          project scopes, client requirements, and seamless communication across
          development, design, and management teams.
        </li>
        <li>
          Conducted technical interviews to evaluate and assess the skills and
          qualifications of junior developers and interns for various projects,
          ensuring the selection of highly capable candidates to contribute to
          the team's success.
        </li>
        <li>
          Provided mentorship and guidance to junior FullStack, Frontend, and
          Backend Developers, aiding their professional growth and skill
          development.
        </li>
        <li>
          Assumed responsibility for code deployment to various environments for
          the projects, regularly conducting code reviews and maintaining
          up-to-date technical documentation.
        </li>
      </ul>
    ),
  },
  {
    name: "Full Stack Engineer",
    where: "Caarya",
    when: "January 2022 - Present",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Leading team's consisting of approximately 20-25 members in the
          development of UI components and APIs for various applications.{" "}
        </li>
        <li>
          Successfully developed 16 Progressive Web Apps, leveraging service
          workers and caching mechanisms to enhance user experience.{" "}
        </li>
        <li>
          Conducted client meetings to thoroughly comprehend client requirements
          and effectively coordinate with respective teams.{" "}
        </li>
        <li>
          Conducted technical interviews to evaluate and assess the skills and
          qualifications of junior developers and interns for various projects,
          ensuring the selection of highly capable candidates to contribute to
          the team's success.
        </li>
        <li>
          Spearheaded project development from inception, overseeing the entire
          lifecycle of app development, from conceptualization to execution.
        </li>
        <li>
          Managed code deployment through effective coordination with DevOps
          engineers.
        </li>
        <li>
          Handled execution of around 12 internal projects and contributed to 4
          client projects.
        </li>
      </ul>
    ),
  },
  {
    name: "Frontend Engineer",
    where: "Caarya",
    when: "April 2021 - December 2021",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Demonstrated leadership by successfully leading and completing 4
          projects.
        </li>
        <li>
          Led a dedicated development team of 5 members, overseeing the
          development of new UI components, integration of new feature sets, and
          ensuring responsiveness across applications.
        </li>
        <li>
          Implemented codebase refactoring to enhance readability, reusability,
          and optimize overall code performance.
        </li>
        <li>
          Developed fully responsive websites, ensuring seamless user
          experiences across various devices and screen sizes.
        </li>
        <li>Mentored and coached 4 junior frontend engineers.</li>
        <li>
          Took charge of code deployment and conducted regular code review
          sessions.
        </li>
      </ul>
    ),
  },
  {
    name: "Full Stack Developer - Trainee",
    where: "Caarya",
    when: "March 2021 - April 2021",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Created and developed new UI components to enhance the application's
          user interface.
        </li>
        <li>
          Successfully integrated the frontend application with backend APIs,
          ensuring smooth communication and data exchange.
        </li>
        <li>
          Implemented client-side search functionality, allowing users to easily
          search and retrieve relevant information.
        </li>
        <li>
          Conducted code refactoring to identify and eliminate potential bugs,
          improving overall code quality and stability.
        </li>
      </ul>
    ),
  },
];

const projects = [
  {
    name: "Chronos - Caarya Suite of Products",
    where: "Caarya",
    when: "Dec 2021 - Present",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Spearheaded the development of 20 diverse applications utilizing
          React, Redux Toolkit, TailwindCSS, and SASS, ensuring consistent
          quality and adherence to best practices.
        </li>
        <li>
          Assumed responsibility for the backend APIs, implementing robust
          authentication and IAM (Identity and Access Management) solutions
          across all 20 applications.
        </li>
        <li>
          Conducted codebase refactoring to enhance readability, reusability,
          and code optimization, resulting in improved maintainability and
          performance.
        </li>
        <li>
          Leveraged Redux Toolkit to efficiently manage user permissions and
          dynamically render different portal layouts based on authorized
          access.
        </li>
        <li>
          Transformed all applications into Progressive Web Apps (PWAs) by
          leveraging service workers and caching mechanisms, enhancing offline
          capabilities and user experience.
        </li>
        <li>
          Developed a Discord bot using Discord.js and Python that offers
          analytical statistics and daily task management capabilities to users
          upon command.
        </li>
        <li>
          Seamlessly integrated Dialogflow and Google APIs into the
          applications, leveraging intent recognition capabilities to enable
          intelligent conversational interactions, provide accurate feedback,
          and unlock additional functionality.
        </li>
      </ul>
    ),
  },

  {
    name: "Txt Eld",
    where: "Caarya",
    when: "Aug 2022 - Jun 2023",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Implemented a PDF and CSV emailing feature using .NET, allowing users
          to generate and send PDF and CSV files via email seamlessly.
        </li>
        <li>
          Developed robust APIs in .NET and seamlessly integrated them into an
          Angular application, facilitating smooth data exchange and
          communication between the frontend and backend.
        </li>
        <li>
          Designed and developed UI components for the Angular application,
          ensuring a visually appealing and userfriendly interface.
        </li>
      </ul>
    ),
  },
  {
    name: "BackBone",
    where: "Caarya",
    when: "Jul 2022 - June 2023",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Led a team of 10 developers and a designer, successfully coordinating
          efforts to meet client requirements and deliver high-quality
          solutions.
        </li>
        <li>
          Collaborated with the client, as well as the design and development
          team, to ensure effective communication and alignment throughout the
          project lifecycle.
        </li>
        <li>
          Collaborated closely with the Product Manager to establish clear
          deliverable timelines, effectively planning and prioritizing daily
          tasks and weekly goals for the team to ensure efficient project
          execution.
        </li>

        <li>
          Provided supervision and guidance in the development of UI components
          and APIs, overseeing code deployments to various environments, and
          conducting regular testing across all environments.
        </li>
        <li>
          Assumed responsibility for deployments and database migrations to
          different environments, while conducting daily code reviews to
          maintain optimal code quality.
        </li>
        <li>
          Managed a team of 6-7 developers over a period of one and a half
          months, successfully overseeing the development of the entire admin
          panel.
        </li>

        <li>
          Integrated Stripe APIs to facilitate seamless payment processing.
        </li>
      </ul>
    ),
  },
  {
    name: "Ramgarhia Sewak Sabha Kolkata",
    where: "Volunteer",
    when: "May 2022",
    link: "https://ramgarhiasewaksabhakolkata.com/",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Created a user-friendly interface and enhanced the digital presence of
          the Ramgarhia Sewak Sabha Kolkata, an organization for the Ramgarhia
          Sikh community in Kolkata.
        </li>
      </ul>
    ),
  },
  {
    name: "Vapra",
    where: "Caarya",
    when: "Mar 2022 - Jan 2023",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Led a team of 10 developers, fostering collaboration and coordination
          between the client, design team, and development team to successfully
          meet project requirements.
        </li>
        <li>
          Collaborated with the Project Manager to establish deliverable
          timelines, daily tasks, and weekly goals, ensuring efficient project
          execution and timely delivery.
        </li>{" "}
        <li>
          Provided supervision and guidance in regular code deployments to
          multiple environments, conducted thorough testing across various
          platforms, and oversaw the development of UI components and APIs.
        </li>{" "}
        <li>
          Reviewed and restructured the database architecture to align with
          client requirements, managing deployments and database migrations to
          different environments.
        </li>{" "}
        <li>
          Developed a user-friendly UI for displaying reports generated by
          Quickbooks APIs, enabling users to download them in PDF, Docx, and
          Excel formats.
        </li>{" "}
        <li>
          Implemented a robust Document Generation feature with over 100
          templates, allowing users to quickly generate documents in less than 5
          minutes.
        </li>{" "}
        <li>
          Oversaw the development of a React Native Android application,
          ensuring adherence to quality standards and timely delivery.
        </li>
      </ul>
    ),
  },
  {
    name: "SAKSHAM",
    where: "Caarya",
    when: "Aug 2021 - Jan 2023",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Demonstrated strong leadership skills by effectively leading a team of
          6 developers, ensuring smooth coordination and collaboration to meet
          client requirements and deliver exceptional solutions.
        </li>
        <li>
          Developed 3 applications and 2 donation apps, leveraging HTML, CSS,
          React, and TailwindCSS to create engaging user experiences while
          adhering to project specifications.
        </li>
        <li>
          Successfully integrated the Razorpay payment gateway with the Python
          backend, enabling secure and efficient processing of payments for
          enhanced user convenience.
        </li>
      </ul>
    ),
  },

  {
    name: "Sportzi - Sports Infotainment Website",
    where: "Caarya",
    when: "Aug 2021 - Sep 2021",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Led a dedicated development team of 3 members, overseeing the
          development of new UI components, integration of new feature sets, and
          maintenance of responsiveness for both the website and admin portal.
        </li>
        <li>
          Facilitated effective communication between the frontend and backend
          teams, ensuring seamless collaboration and information exchange.
        </li>
        <li>
          Collaborated with the Product and Project Managers to establish
          deliverable timelines for the project, ensuring timely completion of
          tasks and milestones.
        </li>
        <li>
          Skilfully planned daily tasks for team members, optimizing
          productivity and resource allocation.
        </li>
        <li>
          Conducted code refactoring to enhance code readability, reusability,
          and optimization, resulting in improved maintainability and
          performance.
        </li>

        <li>
          Designed new screens for the Football section of the website, ensuring
          a visually appealing and user-friendly experience.
        </li>
        <li>
          Integrated a Live updates feature using Socket.io, enabling real-time
          updates and enhanced interactivity.
        </li>
      </ul>
    ),
  },
  {
    name: "Client Projects",
    where: "Caarya",
    when: "Mar 2021 - Sep 2022",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Independently developed websites for 5 diverse clients, utilizing
          technologies such as React, Redux, SASS, CSS, TailwindCSS, and Next.js
          to deliver customized and engaging online experiences.
        </li>
        <li>
          Developed 5 robust admin panels using React, Redux Toolkit, and
          TailwindCSS, providing clients with efficient tools to manage and
          organize their organizational data effectively.
        </li>
        <li>
          Assumed responsibility for the deployment of all websites to various
          environments, ensuring seamless transition and optimal performance.
        </li>
        <li>
          Designed and developed new UI components, enhancing the visual appeal
          and usability of the application.
        </li>
        <li>
          Conducted code refactoring to identify and eliminate potential bugs,
          improving the stability and performance of the application.
        </li>
      </ul>
    ),
  },
];

const involvements = [
  {
    name: "Core Member - Google Developer's Student Club",
    where: "Techno International Batanagar",
    when: "Aug 2020 - Jul 2021",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Actively participated in weekly planning sessions for the club,
          contributing ideas and insights to develop strategies and initiatives.
        </li>
        <li>
          Assumed responsibility for content creation related to upcoming
          events, ensuring engaging and informative materials for promotion and
          communication purposes.
        </li>
        <li>
          Skilfully created graphics for social media platforms, effectively
          capturing the club's message and attracting attention from the target
          audience.
        </li>
      </ul>
    ),
  },
  {
    name: "Organizer - Extra Curricular Activities Club",
    where: "Techno International Batanagar",
    when: "Jul 2018 - Mar 2020",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Led the successful organization of the Freshers Welcome Program,
          orchestrating a seamless and engaging onboarding experience for the
          incoming 2018 batch of newcomers.
        </li>
        <li>
          Took an active role in the planning and execution of the annual sports
          event for the year 2019, contributing to the seamless coordination of
          various activities and ensuring a successful and memorable experience
          for all participants.
        </li>
        <li>
          Took charge of planning and executing the Teachers' Day program in
          September 2019, ensuring a memorable and appreciative event for the
          school's educators.
        </li>
        <li>
          Actively contributed to the coordination and execution of the blood
          donation camp in both 2018 and 2019, supporting a vital community
          initiative.
        </li>
      </ul>
    ),
  },
  {
    name: "Master of Ceremonies - Akshay Urja Diwas",
    where: "Techno International Batanagar",
    when: "Aug 2018",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Demonstrated exceptional skills as a Master of Ceremonies, seamlessly
          guiding and engaging audiences through various event segments.
        </li>
        <li>
          Displayed confident and charismatic stage presence, enhancing the
          event's atmosphere and leaving a lasting positive impression.
        </li>
        <li>
          Maintained a well-paced event timeline, coordinating with performers,
          speakers, and technical teams to ensure seamless transitions and
          timely execution.
        </li>
        <li>
          Navigated unexpected situations and last-minute changes with
          composure, quick thinking, and efficient problem-solving skills.
        </li>
        <li>
          Collaborated closely with event organizers, vendors, and performers to
          align event vision, ensuring a cohesive and successful overall
          production.
        </li>
        <li>
          Demonstrated a professional demeanour at all times, upholding event
          standards and contributing to a positive and memorable event
          experience.
        </li>
      </ul>
    ),
  },
  {
    name: "Volunteer - Medical Check-up Camp",
    where: "Kolkata Sikh Nari Manch",
    when: "Jul 2017 - Aug 2017",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Successfully organized and coordinated medical check-up camps,
          ensuring seamless logistical arrangements and a smooth flow of
          operations.
        </li>
        <li>
          Actively participated in promoting health awareness by offering free
          medical services, contributing to community well-being and preventive
          care.
        </li>
        <li>
          Collaborated effectively with a multidisciplinary team of healthcare
          professionals, contributing to the collective effort in providing
          quality medical services.
        </li>
        <li>
          Actively engaged with camp attendees, answering queries, and
          addressing concerns, fostering positive relationships and enhancing
          the camp's impact.
        </li>
      </ul>
    ),
  },
  {
    name: "Deputy Head Girl - Student Council",
    where: "M. P. Birla Foundation H.S. School",
    when: "Jul 2015 - Aug 2016",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Served as a representative for the school, actively participating and
          representing the institution in various school events.{" "}
        </li>
        <li>
          Demonstrated strong leadership skills by effectively managing a
          council of 52 students, overseeing their day-today school duties and
          responsibilities.
        </li>
        <li>
          Successfully organized a variety of inter-school and intra-school
          events throughout the year, delegating tasks and responsibilities to
          student council members, ensuring smooth execution and memorable
          experiences for all participants.
        </li>
      </ul>
    ),
  },
  {
    name: "Basketball Player",
    where: "M. P. Birla Foundation H.S. School",
    when: "Jun 2014 - Dec 2016",
    description: (
      <ul style={{ listStyle: "disc" }}>
        <li>
          Participated as a dedicated member of the school team, showcasing my
          skills and commitment on the field in both the years 2014 and 2015.
        </li>
        <li>
          Led the house basketball team as captain during the intra-school
          tournament, guiding the team to a commendable second-place position
          through effective leadership and a display of sportsmanship in 2015.
        </li>
        <li>
          As coach, led the house basketball team to a first-place victory in
          the intra-school tournament of 2016, fostering skill development and
          teamwork.
        </li>
      </ul>
    ),
  },
];

const certifications = [
  {
    name: "Java Programming and Software Engineering Fundamentals Specialization",
    where: "Coursera (Duke University)",
    when: "2020",
    description: (
      <p>
        Includes the following modules: Arrays, Lists, and Structured Data,
        Programming Foundations with JavaScript, HTML and CSS, Solving Problems
        with Software, Principles of Software Design, Building a Recommendation
        System
      </p>
    ),
  },
  {
    name: "The Bits and Bytes of Computer Networking",
    where: "Coursera (Google)",
    when: "2020",
    description: null,
  },
  {
    name: "Version Control with Git",
    where: "Coursera (Atlassian)",
    when: "2020",
    description: null,
  },
  {
    name: "Introduction to Git and GitHub",
    where: "Coursera (Google)",
    when: "2020",
    description: null,
  },
  {
    name: "Big Data - Hadoop",
    where: "Sikharty Educational Service",
    when: "2019",
    description: null,
  },
  {
    name: "Seminar on Blockchain & Recent Trends in Computing",
    where: "Association for Computing Machinery & Computer Society of India",
    when: "2019",
    description: null,
  },
  {
    name: "Core Java",
    where: "Techno International Batanagar",
    when: "2018",
    description: null,
  },
  {
    name: "PHP MYSQL with HTML CSS",
    where: "NIEDC Entrepreneurship Development Training Programme",
    when: "2018",
    description: null,
  },
];

const dataportfolio = [
  "/assets/images/snap/13.jpg",
  //   "/assets/images/snap/4.jpg",
  "/assets/images/snap/12.jpg",
  "/assets/images/snap/6.jpg",
  "/assets/images/snap/3.jpg",

  "/assets/images/snap/11.jpg",
  "/assets/images/snap/9.jpg",

  "/assets/images/snap/7.jpg",
  "/assets/images/snap/2.jpg",

  "/assets/images/snap/14.jpg",

  //   "/assets/images/snap/8.jpg",
  "/assets/images/snap/10.jpg",

  "/assets/images/snap/1.jpg",
];

const contactConfig = {
  YOUR_EMAIL: "iamasukaur@gmail.com",
  YOUR_NUMBER: "(+91) 9831983642",
  description:
    "I'm thrilled to connect. Whether you have a project in mind, want to collaborate, or just fancy a chat, I'm all ears. Feel free to drop me a message below, and I'll get back to you as soon as possible. Looking forward to hearing from you!",
  YOUR_SERVICE_ID: "service_id",
  YOUR_TEMPLATE_ID: "template_id",
  YOUR_USER_ID: "user_id",
};

const socialprofils = {
  github: "https://github.com/AsuKaur",
  facebook: "",
  linkedin: "https://www.linkedin.com/in/asukaur/",
  twitter: "https://twitter.com/asu_kaur?lang=en",
  instagram: "https://www.instagram.com/asu.kaur/",
};

const allSkills = {
  softSkills: [
    {
      name: "Leadership",
      value: 90,
    },
    {
      name: "Organization",
      value: 85,
    },
    {
      name: "Time Management",
      value: 80,
    },
    {
      name: "Prioritization",
      value: 60,
    },
    {
      name: "Planning",
      value: 85,
    },
    {
      name: "Ideation",
      value: 80,
    },
    {
      name: "Teamwork",
      value: 60,
    },
    {
      name: "Coordination",
      value: 85,
    },
    {
      name: "Communication",
      value: 80,
    },
    {
      name: "Mentoring",
      value: 60,
    },
  ],
  technicalLanguages: [
    {
      name: "Javascript",
      value: 90,
      icon: (
        <>
          <SiJavascript />
        </>
      ),
    },
    {
      name: "Java",
      value: 85,
      icon: (
        <>
          <FaJava />
        </>
      ),
    },
    {
      name: "Python",
      value: 80,
      icon: (
        <>
          <FaPython />
        </>
      ),
    },
    {
      name: "HTML",
      value: 60,
      icon: (
        <>
          <FaHtml5 />
        </>
      ),
    },
    {
      name: "CSS",
      value: 85,
      icon: (
        <>
          <FaCss3 />
        </>
      ),
    },
    {
      name: "SCSS",
      value: 85,
      icon: (
        <>
          <BsFiletypeScss />
        </>
      ),
    },
    {
      name: "C",
      value: 60,
      icon: (
        <>
          <TbHexagonLetterC />
        </>
      ),
    },
    {
      name: "C#",
      value: 85,
      icon: (
        <>
          <TbHexagonLetterC />
        </>
      ),
    },
  ],
  technicalSkills: [
    {
      name: "React",
      value: 90,
      icon: (
        <>
          <FaReact />
        </>
      ),
    },
    {
      name: "React Native",
      value: 85,
      icon: (
        <>
          <TbBrandReactNative />
        </>
      ),
    },
    {
      name: "Node.js",
      value: 80,
      icon: (
        <>
          <FaNodeJs />
        </>
      ),
    },
    {
      name: "Angular",
      value: 60,
      icon: (
        <>
          <FaAngular />
        </>
      ),
    },
    {
      name: "Flask",
      value: 85,
      icon: (
        <>
          <SiFlask />
        </>
      ),
    },
    {
      name: ".NET",
      value: 60,
      icon: (
        <>
          <SiDotnet />
        </>
      ),
    },
    {
      name: "Redux Toolkit",
      value: 85,
      icon: (
        <>
          <SiRedux />
        </>
      ),
    },
    {
      name: "Express.js",
      value: 60,
      icon: (
        <>
          <SiExpress />
        </>
      ),
    },
    {
      name: "Axios",
      value: 85,
      icon: (
        <>
          <SiAxios />
        </>
      ),
    },
    {
      name: "Firebase",
      value: 60,
      icon: (
        <>
          <SiFirebase />
        </>
      ),
    },
    {
      name: "PostgreSQL",
      value: 85,
      icon: (
        <>
          <BiLogoPostgresql />
        </>
      ),
    },
    {
      name: "MySQL",
      value: 60,
      icon: (
        <>
          <SiMysql />
        </>
      ),
    },
    {
      name: "MongoDB",
      value: 85,
      icon: (
        <>
          <SiMongodb />
        </>
      ),
    },
    {
      name: "TailwindCSS",
      value: 60,
      icon: (
        <>
          <SiTailwindcss />
        </>
      ),
    },
    {
      name: "Booststrap",
      value: 85,
      icon: (
        <>
          <SiBootstrap />
        </>
      ),
    },
    {
      name: "MUI",
      value: 85,
      icon: (
        <>
          <SiMui />
        </>
      ),
    },
    {
      name: "DialogFlow",
      value: 60,
      icon: (
        <>
          <SiDialogflow />
        </>
      ),
    },
    {
      name: "Discord.js",
      value: 85,
      icon: (
        <>
          <FaDiscord />
        </>
      ),
    },
    {
      name: "Next.js",
      value: 60,
      icon: (
        <>
          <TbBrandNextjs />
        </>
      ),
    },
  ],
  software: [
    {
      name: "Git",
      value: 90,
      icon: (
        <>
          <BsGithub />
        </>
      ),
    },
    {
      name: "Github",
      value: 85,
      icon: (
        <>
          <DiGithubFull />
        </>
      ),
    },
    {
      name: "VSCode",
      value: 80,
      icon: (
        <>
          <TbBrandVscode />
        </>
      ),
    },
    {
      name: "Figma",
      value: 60,
      icon: (
        <>
          <FaFigma />
        </>
      ),
    },
    {
      name: "Visily",
      value: 85,
      icon: (
        <>
          <TbSquareLetterV />
        </>
      ),
    },
    {
      name: "Postman",
      value: 60,
      icon: (
        <>
          <SiPostman />
        </>
      ),
    },
    {
      name: "Notion",
      value: 85,
      icon: (
        <>
          <SiNotion />
        </>
      ),
    },
    {
      name: "Jira",
      value: 60,
      icon: (
        <>
          <FaJira />
        </>
      ),
    },
    {
      name: "Discord",
      value: 85,
      icon: (
        <>
          <FaDiscord />
        </>
      ),
    },
    {
      name: "Miro",
      value: 85,
      icon: (
        <>
          <SiMiro />
        </>
      ),
    },
  ],
};

const skills = allSkills?.technicalSkills;

const hobbies = [
  {
    name: "Listening to Music",
    icon: (
      <>
        <FaHeadphones />
      </>
    ),
  },
  {
    name: "Photography",
    icon: (
      <>
        <FaCamera />
      </>
    ),
  },
  {
    name: "Reading",
    icon: (
      <>
        <FaBook />
      </>
    ),
  },
  {
    name: "Travelling",
    icon: (
      <>
        <FaPlane />
      </>
    ),
  },
];

const sports = [
  {
    name: "Swimming",
    icon: (
      <>
        <FaSwimmer />
      </>
    ),
  },
  {
    name: "Running",
    icon: (
      <>
        <FaRunning />
      </>
    ),
  },
  {
    name: "Basketball",
    icon: (
      <>
        <FaBaseballBall />
      </>
    ),
  },
];
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  projects,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
  education,
  allSkills,
  personalTimeline,
  hobbies,
  sports,
  dataacademic,
  datawork,
  involvements,
  certifications,
};
